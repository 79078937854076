const CaseStudyStrings = {
  // Culture Biosciences
  CULTURE_BIOSCIENCES_TITLE: 'Culture Biosciences',
  CULTURE_BIOSCIENCES_SUBTITLE: 'GraphQL and Front End Foundations',
  CULTURE_BIOSCIENCES_DESCRIPTION:
    'Engineering foundation for customer application to monitor experiments.',
  
  // Facebook
  FACEBOOK_TITLE: 'Facebook',
  FACEBOOK_SUBTITLE: 'Mobile, Web, iOS, Android',
  FACEBOOK_DESCRIPTION:
    "Cross platform application for monitoring and responding to alerts in Facebook's global datacenters.",
  
  // Peachjar
  PEACHJAR_TITLE: 'Peachjar',
  PEACHJAR_SUBTITLE: 'Mobile first web app',
  PEACHJAR_DESCRIPTION:
    "An app that allows parents to access the latest opportunities from their children's schools.",
};

const Strings = {
  ...CaseStudyStrings,
};

export default Strings;
