import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from '../Image';

const query = graphql`
  query {
    file(relativePath: { eq: "culture-biosciences-case-preview.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const CultureBiosciencesCasePreviewImage = props => (
  <StaticQuery
    query={query}
    render={data => (
      <Image fluid={data.file.childImageSharp.fluid} {...props} />
    )}
  />
);

export default CultureBiosciencesCasePreviewImage;
